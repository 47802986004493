import $ from 'jquery';
import 'slick-carousel';

(function() {
  'use strict';


  /**
  ** ARTICLES
  ************************************************************/

  $('.js-slider[data-type="articles"] .js-slider-items').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 719,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 959,
        settings: {
          infinite: false,
          slidesToShow: 3
        }
      }
    ]
  });


  /**
  ** REFRENCES
  ************************************************************/

  // Events

  $('.js-slider[data-type="references"] .js-slider-items').on('init', function(event, slick) {
    var $slider = slick.$slider.parents('.js-slider');
    $slider.find('.js-slider-prev').addClass('is-disabled');
  });

  $('.js-slider[data-type="references"] .js-slider-items').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
    var $slider = slick.$slider.parents('.js-slider');

    if(nextSlide > currentSlide) {
      $slider.find('.js-slider-prev').removeClass('is-disabled');

      if((slick.slideCount - (nextSlide + slick.options.slidesToShow)) == 0) {
        $slider.find('.js-slider-next').addClass('is-disabled');
      }
    }
    else {
      $slider.find('.js-slider-next').removeClass('is-disabled');

      if(nextSlide == 0) {
        $slider.find('.js-slider-prev').addClass('is-disabled');
      }
    }
  });


  // Init

  $('.js-slider[data-type="references"] .js-slider-items').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 2
        }
      }
    ],
  });


  /**
  ** CONTROLS
  ************************************************************/

  $('.js-slider-prev').on('click', function() {
    $(this).parents('.js-slider').find('.js-slider-items').slick('slickPrev');
  });

  $('.js-slider-next').on('click', function() {
    $(this).parents('.js-slider').find('.js-slider-items').slick('slickNext');
  });

})();
