/**
** JAVASCRIPT
** Name: Form Validation
********************************************************************************/


const $emailInputs = document.querySelectorAll('input[type="email"]');

if ($emailInputs.length > 0) {
  $emailInputs.forEach(($emailInput) => {
    $emailInput.addEventListener('input', () => {
      $emailInput.setCustomValidity('');
      $emailInput.checkValidity();
    });

    $emailInput.addEventListener('invalid', () => {
      if ($emailInput.value === '') {
        $emailInput.setCustomValidity('');
      } else {
        $emailInput.setCustomValidity('Veuillez saisir une adresse email valide. Par exemple, nom@domaine.fr');
      }
    });
  });
}

