/**
** JAVASCRIPT
** Name: Showcase
********************************************************************************/

(function() {
  'use strict';


  /**
  ** Variables
  ****************************************/

  var $win      = $(window),
      $showcase = $('.js-showcase'),
      $showcasebackcontainer = $('.js-showcase__backface .l-container'),
      $showcasefrontcontainer = $('.js-showcase__frontface .l-container'),
      $showcasebackcontainerContent = $('.js-showcase__backface .c-showcase__container'),
      $showcasefrontcontainerContent = $('.js-showcase__frontface .c-showcase__container'),
      $buttonShowcase = $('.c-showcase__button'),
      $buttonFront = $('.js-showcase__frontface .js-showcase-button'),
      $buttonBack = $('.js-showcase__backface .js-showcase-button');


  /**
  ** FUNCTION
  ** Animate introduction
  ****************************************/

  function startShowcase() {
    $showcase.addClass('animate-in');

    setTimeout(function() {
      $showcase.removeClass('animate-in').addClass('animate-out');
    }, 1500);
  }

  function initHeight() {
    var containerHeight;
    if (window.matchMedia('(max-width: 960px)').matches) {
      if ($showcasebackcontainer.height()> $(window).height() || $showcasefrontcontainer.height()> $(window).height() ) {
        if ($showcasebackcontainer.height()>$showcasefrontcontainer.height()) {
          containerHeight = $showcasebackcontainer.height();
        } else {
          containerHeight = $showcasefrontcontainer.height();
        }

        containerHeight= containerHeight + 100;

        $showcase.css("height",containerHeight + "px");
        $showcasebackcontainerContent.css("height",containerHeight + "px");
        $showcasefrontcontainerContent.css("height",containerHeight + "px");
      }
    }
  }


  /**
  ** Init
  ****************************************/

  $win.on('load', function() {
    initHeight();
    startShowcase();
    $(window).resize(function(){
      initHeight();
    })
  });


  /**
  ** Click
  ****************************************/

  $buttonFront.on('click', function() {
    $showcase.toggleClass('is-reversed');

    $buttonBack.attr("tabindex", "0");
    $buttonFront.attr("tabindex", "-1");

    $('.js-showcase__backface').focus();

    $('.js-showcase__frontface').attr('aria-hidden', 'true');
    $('.js-showcase__backface').attr('aria-hidden', 'false');

    $buttonFront.attr('aria-expanded', 'false');
    $buttonBack.attr('aria-expanded', 'true');

    $buttonShowcase.attr("tabindex", "0");
  });

  $buttonBack.on('click', function() {
    $showcase.toggleClass('is-reversed');

    $buttonBack.attr("tabindex", "-1");
    $buttonFront.attr("tabindex", "0");

    $('.js-showcase__frontface').focus();

    $('.js-showcase__frontface').attr('aria-hidden', 'false');
    $('.js-showcase__backface').attr('aria-hidden', 'true');

    $buttonFront.attr('aria-expanded', 'true');
    $buttonBack.attr('aria-expanded', 'false');

    $buttonShowcase.attr("tabindex", "-1");

  });

})();
